@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'react-modern-calendar-datepicker/lib/DatePicker.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Roboto', system-ui;
    overflow-x: hidden;
}

/* Common css  */
@layer components {
    .btn-primary {
        @apply text-white px-[45px] text-[18px] rounded-[6px] font-normal py-[10px];
    }
    .para {
        @apply text-[1rem] leading-[1.5] font-[400];
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.background-gradient-color:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-mask-image: linear-gradient(to bottom, transparent, black);
    mask-image: linear-gradient(to bottom, transparent, black);
    background: linear-gradient(90deg, #3a3b3d, #354ba0);
}
.background-gradient-color {
    background: linear-gradient(90deg, #d97b50, #01a9e2);
    position: relative;
    z-index: 1;
}
.background-color {
    background: linear-gradient(45deg, #db7b50, #00aae2, #364a9f, #3a3a3d);
    background-size: 100%;
    animation: color 12s ease-in-out infinite;
}

@keyframes color {
    0% {
        background-position: 0 50%;
    }
    40% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

html {
    scroll-behavior: smooth;
}
.gradient.top {
    position: absolute;
    left: 0;
    top: -0.1%;
    right: 0;
    bottom: auto;
    height: 80%;
    transform: rotate(0deg);
}
.gradient.bottom {
    position: absolute;
    left: 0;
    top: auto;
    right: 0%;
    bottom: -0.1%;
    height: 80%;
    transform: rotate(180deg);
}

.gradient {
    left: 0;
    top: auto;
    right: 0;
    bottom: 0%;
    transform: rotate(180deg);
    background: linear-gradient(
        to bottom,
        black 0%,
        rgba(0, 0, 0, 0.738) 19%,
        rgba(0, 0, 0, 0.541) 34%,
        rgba(0, 0, 0, 0.382) 47%,
        rgba(0, 0, 0, 0.278) 56.5%,
        rgba(0, 0, 0, 0.194) 65%,
        rgba(0, 0, 0, 0.126) 73%,
        rgba(0, 0, 0, 0.075) 80.2%,
        rgba(0, 0, 0, 0.042) 86.1%,
        rgba(0, 0, 0, 0.021) 91%,
        rgba(0, 0, 0, 0.008) 95.2%,
        rgba(0, 0, 0, 0.002) 98.2%,
        transparent 100%
    );
}

.bg-gradient {
    background: linear-gradient(to right, #d97b50, #01a9e2, #3a3b3d, #354ba0, #d97b50);
    background-size: 400% 400%;
    -webkit-animation: gradientTranisition 50s linear infinite;
    animation: gradientTranisition 50s linear infinite;
    width: 100%;
}
.background-animate {
    background-size: 500%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}
@keyframes AnimationName {
    0%,
    100% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}
.contact_us header {
    z-index: 10 !important;
    color: white !important;
}
.react-datetime-picker__inputGroup__divider {
    margin: 0px 10px;
}
.react-datetime-picker__inputGroup {
    margin: auto;
    min-height: 54px;
    justify-content: space-between;
    align-items: center;
    height: 100% !important;
    display: flex;
    padding: 0px 20px;
}
.react-calendar {
    background-color: black !important;
    z-index: 200 !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: black !important;
}
.date-button input {
    outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid white;
    -webkit-text-fill-color: #f1f1f1;
    transition: background-color 5000s ease-in-out 0s;
}

.services-bg {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.69), hsla(0deg, 0%, 4%, 0) 94%);
}
.services-root {
    background: linear-gradient(to right, #d97b50, #01a9e2, #3a3b3d, #354ba0, #d97b50);
    background-size: 400% 400%;
    -webkit-animation: gradientTranisition 50s linear infinite;
    animation: gradientTranisition 50s linear infinite;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.react-multi-carousel-list {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.icon-scroll {
    position: absolute !important;
}

.swal2-title {
    font-size: 24px;
}

select option {
    color: black !important;
}
input:focus,
select:focus {
    outline: none !important;
}

.wave-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wave-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    z-index: 1; /* Ensure the image is on top of the waves */
}

.wave-container::before,
.wave-container::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2), transparent);
    animation: waveEffect 3s infinite ease-in-out;
}

.wave-container::after {
    animation-delay: 1.5s; /* Stagger the waves for a better effect */
}

@keyframes waveEffect {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
}

.popver-menu-design {
    background-color: rgb(255 255 255 / 10%) !important;
    backdrop-filter: blur(20px) !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
}

.book-a-call .react-calendar {
    background-color: transparent !important;
    border: none;
}
.book-a-call .react-calendar .react-calendar__tile--active {
    background-color: #354ba0 !important;
    color: white !important;
    border-radius: 100%;
}
.book-a-call .react-calendar .react-calendar__tile:hover {
    background-color: #354ba0 !important;
    color: white !important;
    border-radius: 100%;
}
.book-a-call .react-calendar__tile--now {
    background-color: transparent !important;
}
.book-a-call .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 11px !important;
    color: white;
}
.book-a-call .react-calendar button {
    background-color: transparent !important;
    color: white !important;
    border: none;
}
.book-a-call .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
}

.book-a-call .react-calendar__tile:disabled {
    color: #ababab !important;
    cursor: not-allowed;
}
.book-a-call .react-calendar__tile:disabled abbr {
    color: #ababab !important;
    background-color: transparent !important;
    cursor: not-allowed;
}
.service-sec h2{ text-align: left; }
.service-sec p{ text-align: left; font-size: 1rem; line-height: 23px;letter-spacing: 0.5px; padding-top: 5px;}
